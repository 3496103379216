class Headers {
  constructor (controller) {
    // get all headers in slides that trigger animation
    // let headers = [
    // '#slide01 .wrapper',

    // '#slide03 .wrapper',
    // '#slide04 .wrapper',
    // '#slide05 heasder',
    // '#books header',
    // '.book-advert header',
    // '.animateHeader header'
    // ,
    // '.animateIn header',
    // '.animateIn h5'
  // ];

  // headers.forEach(function (header, index) {
  //   new ScrollMagic.Scene({
  //     triggerHook: 0.5 ,
  //     triggerElement: header,
  //     offset: 0
  //     , duration: 1000000
  //   })
  //     .setClassToggle(header, 'is-active')
  //     .addTo(controller);
  // });

    $('.animateIn').each(function (index, elem) {
      $(elem).find('p').addClass('slideInUp');

      // let duration = $(window).innerHeight() + $(elem).innerHeight() - 40;
      // if ($(window).innerHeight() < $(elem).innerHeight()) {
      //   duration = $(elem).innerHeight() + $(elem).position().top + $(window).innerHeight();
      // }

      new ScrollMagic.Scene({
        triggerElement: elem,
        triggerHook: 0.75,
        offset: 80,
        duration: 1000000
      })
        .setClassToggle(elem, 'is-active')
        .addTo(controller);
      // .addIndicators()
    });

  // this is for the story pages blocks of p and img
  $('.animateInBlocks').each(function (index, elem) {
    $(elem).find('img').each(function () {
      $(this).addClass('slideInUp').wrap('<div></div>');
      new ScrollMagic.Scene({
        duration: 100000,
        triggerElement: $(this).parent('div')[0],
        triggerHook: 1,
        offset: 50
      })
        .setClassToggle($(this).parent('div')[0], 'is-active')
        .addTo(controller);
    });
    $(elem).find('p').each(function () {
      $(this).addClass('slideInUp').wrap('<div></div>');
      // let duration = $(window).innerHeight() + $(this).height() - 120;

      new ScrollMagic.Scene({
        duration: '100000',
        triggerElement: $(this).parent('div')[0],
        triggerHook: 1,
        offset: 50
      })
        .setClassToggle($(this).parent('div')[0], 'is-active')
        .addTo(controller);
        // .addIndicators();
    });
  });
  }
}

export default Headers;
