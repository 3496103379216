class Preloader {
  constructor (introReverseTl) {
    this.bcg = $('.bcsg, .cover, #story-detail img');
    this.body = $('body');
    this.introPara = $('#intro p');
    this.introTitle = $('#intro .title');
    this.preloader = $('#preloader');
    this.progressSpan = $('.progress span');
    this.scrollHint = $('.scroll-hint');
    this.txtPerc = $('.txt-perc');
    this.progress = $('.progress');
    this.introReverseTl = introReverseTl;

    this.imagesLoad = this.bcg.length;
    this.loadingProgress = 0;
    this.loadedCount = 0;

    // Progress timeline animation
    this.progressTl = new TimelineMax({
      paused: true,
      onUpdate: this.progressUpdate.bind(this),
      onComplete: this.loadComplete.bind(this)
    });

    this.init();
  }

  init () {
    // fix to stop the intro messing up if it's scrolled already
    // $(window).on('beforeunload', function () {

    //   if (window.location.pathname === '/') {
    //     $(window).scrollTop(0);
    //   }
    // });

    if (this.bcg.length) {
      this.bcg
        .imagesLoaded({
          background: true
        })
        .progress((instance, image) => {
          this.loadProgress();
        });
    } else {
      this.loadProgress();
    }

    this.progressTl.to(this.progressSpan, 1, {
      width: 100,
      ease: Linear.easeNone
    });
  }

  loadProgress (imageLoad, image) {
    this.loadedCount++;
    this.loadingProgress = (this.loadedCount / this.imagesLoad);

    TweenLite.to(this.progressTl, 0.7, {
      progress: this.loadingProgress,
      ease: Linear.easeNone
    });
  }

  progressUpdate () {
    this.loadingProgress = Math.round(this.progressTl.progress() * 100);
    this.txtPerc.text(this.loadingProgress + '%');
  }

  loadComplete () {

    if (this.introReverseTl) {
      this.introReverseTl.play();
    }

    let preloaderOutTl = new TimelineMax();

    preloaderOutTl
      .to(this.progress, 0.3, {
        y: 100,
        autoAlpha: 0,
        ease: Back.easeIn
      })
      .to(this.txtPerc, 0.3, {
        y: 100,
        autoAlpha: 0,
        ease: Back.easeIn
      }, 0.1)

      .set(this.body, {
        className: '-= is-loading'
      })
      .set(this.body, {
        className: '+=is-loaded'
      })
      .to(this.preloader, 0.7, {
        yPercent: 100,
        ease: Power4.easeInOut
      })
      .call(this.addInitialClass)
      .call(this.addCookieConsent)
      .set(this.preloader, {
        className: '+=is-hidden'
      })
      .from(this.introTitle, 1, {
        autoAlpha: 0,
        ease: Power1.easeOut
      }, '-=0.2')

      .from(this.introPara, 0.7, {
        autoAlpha: 0,
        ease: Power1.easeOut
      }, '+=0.2')
      .from(this.scrollHint, 0.3, {
        y: -20,
        autoAlpha: 0,
        ease: Power1.easeOut
      }, '+=0.1');

    return preloaderOutTl;
  }
  addCookieConsent() {
    window.cookieconsent.initialise({
      "palette": {
        "popup": {
          "background": "#00042d75"
        },
        "button": {
          "background": "transparent",
          "text": "#f5cd85",
          "border": "#f5cd85"
        }
      },
      "position": "bottom-right",
      "content": {
        "href": "/privacy-policy"
      }
    });
  }
  addInitialClass () {
    $('.intro').addClass('is-active');
  }
}

export default Preloader;
