class SlowSlides {
  constructor (controller) {
    let slowSlides = ['#slide01', '.parallax-holder'];

    // SCENE 3 - parallax effect on first slide of the slides with bcg
    // move bcg container when intro gets out of the view
    slowSlides.forEach(function (slide, index) {
      let $bcg = $(slide).find('.parallax');

      new ScrollMagic.Scene({
        triggerElement: slide,
        triggerHook: 0,
        duration: '100%'
      })
        .setTween(TweenMax.to($bcg, 1, {
          y: '-5%',
          x: '-2%',
          scale: 1.2,
          autoAlpha: 0.5,
          ease: Power0.easeNone
        }))
        // .setPin(slide)
        // .addIndicators({name: "pin" +slide})
        .addTo(controller);
    });
  }
}

export default SlowSlides;
