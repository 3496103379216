class Utilities {
  // flatten object by concatting values
  static concatValues (obj) {
    let value = '';
    for (let prop in obj) {
      value += obj[ prop ];
    }
    return value;
  }

  static oneDecimal (n) {
    let number = n;
    let rounded = Math.round(number * 10) / 10;
    return rounded;
  }

  static toPercentage (x, n) {
    let p = 0;
    if (n) {
      p = ((x + 10) / 20) * 100;
    } else {
      p = ((x + 10) / 20);
    }
    return Utilities.oneDecimal(p);
  }

  static initIsInViewport () {
    $.fn.isInViewport = function () {
      let elementTop = $(this).offset().top;
      let elementBottom = elementTop + $(this).outerHeight();
      let viewportTop = $(window).scrollTop();
      let viewportBottom = viewportTop + $(window).height();
      return elementBottom > viewportTop && elementTop < viewportBottom;
    };
  }

  static removeInitial () {
    $('#adventure-logo').removeClass('initial');
  }
}

export default Utilities;
