/* eslint new-cap: ["error", { "newIsCapExceptions": ["klass"] }] */
class AnimationController {
  constructor () {
    this.controller = new ScrollMagic.Controller();
    this.components = {};

    // change behaviour of controller to animate scroll instead of jump
    this.controller.scrollTo(function (newpos) {
      // console.log(newpos);
      TweenMax.to(window, 0.5, {
        scrollTo: {
          y: newpos
        },
        ease: Power1.easeInOut
      });
    });
  }

  add (klass) {
    let component = new klass(this.controller);
    this.components[klass.name] = component;
    return component;
  }
}

export default AnimationController;
