import Utilities from './utilities';
class StoryFilter {
  constructor (controller) {
    let filters = {};
    this.orderSelect = $('#selectbox2');

    // $('.filters select').selectric({
    //   arrowButtonMarkup: '<b class="btn">&#x25be;</b>'
    // });

    let $grid = $('.story-isotope');

    $grid.imagesLoaded(function () {
      $grid.isotope({
        // options
        percentPosition: true,
        itemSelector: '.grid-item',
        masonry: {
          columnWidth: '.grid-sizer',
          gutter: '.gutter-sizer'
        }
        //,

        // sortBy: 'releaseDate',
        // sortAscending: false,
        // getSortData: {
        //   name: '.name',
        //   releaseDate: '.release-date parseInt',
        //   bookDate: '.book-date parseInt'
        // }
      });
    });

    $('.grid-item .img').click((e) => {
      $('.grid-information').removeClass('selected-book');

      let $this = $(e.currentTarget);

      let $gridInformation = $this.siblings('.grid-information');
      console.log($gridInformation);
      $gridInformation.show();
      $gridInformation.addClass('selected-book');

      Utilities.initIsInViewport();

      // scroll to the information box,
      // if we cant see the link
      if (!$this.parent().find('.grid-information a').isInViewport()) {
        controller.scrollTo($this.parent().find('.grid-information').offset().top - 55);
      }
      // Should we be unbinding this??
      $(document).click((e) => {
        if (!e.target.matches('.img')) {
          $('.grid-information').removeClass('selected-book');
        }
      });
    });
  }
}

export default StoryFilter;
