import Preloader from './modules/preloader';
import MainNav from './modules/main-nav-and-modals';
import Utilities from './modules/utilities';
import BookGallery from './modules/book-gallery';
import BookFilter from './modules/book-filter';
import StoryFilter from './modules/story-filter';
import Parallax from './modules/parallax';

import AnimationController from './modules/animation-controller';
import Headers from './modules/headers';
import Intro from './modules/intro';
import Slides from './modules/slides';
import SlowSlides from './modules/slow-slides';
import Videos from './modules/videos';
import BuyNowButton from './modules/buy-now-button';

$(function () {
  const animationController = new AnimationController();
  const intro = animationController.add(Intro);

  // Other animations
  animationController.add(Headers);
  animationController.add(Slides);
  animationController.add(SlowSlides);
  animationController.add(Videos);

  // .add(Headers);

  const PROJECT = {
    preloader: new Preloader(intro.introReverseTl),
    mainNav: new MainNav(animationController.controller),
    utilities: new Utilities(),
    bookGallery: new BookGallery(),
    bookFilter: new BookFilter(animationController.controller),
    storyFilter: new StoryFilter(animationController.controller),
    parallax: new Parallax(),
    animationController: animationController,
    buynowbutton: new BuyNowButton()
  };

  window.PROJECT = PROJECT;
});
