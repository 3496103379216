class BookGallery {
  constructor () {
    // GALLERY FOR BOOKS ON HOMEPAGE
    let $carousel = $('.main-carousel');

    if ($carousel.length) {
      let $gallery = $carousel.flickity({
        cellAlign: 'center',
        percentPosition: true,
        // prevNextButtons: false,
        pageDots: false,
        on: {
          ready: function () {
            // console.log('Flickity is ready');
            // $('.main-carousel').closest('.carousel-cell').addClass('selected-book');
          },
          settle: function (event, index) {
            // =ADD css class when finished moving - to load the book information
            $cellItems.eq(flkty.selectedIndex)
              .find('.carousel-information')
              .addClass('selected-book');
          },
          staticClick: function (event, pointer, cellElement, cellIndex) {
            // = move to the correct cell when clicked
            if (typeof cellIndex === 'number') {
              $('.main-carousel').flickity('select', cellIndex);
            }
          },
          change: function (index) {
            $('.carousel-information').removeClass('selected-book');
            //= =   console.log( 'Slide changed to' + index );
          }
        }
      });

      // Some helpful vars for the flickity jobbie above
      let $cellItems = $gallery.find('.carousel-cell');
      let flkty = $gallery.data('flickity');

      if (flkty) {
        // set the intial view. This should probably move into the scene code to be triggered at the right time.
        $cellItems.eq(flkty.selectedIndex)
          .find('.carousel-information')
          .addClass('selected-book');
      }
    }
  }
}

export default BookGallery;
