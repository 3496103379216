class MainNav {
  constructor (controller) {
    this.navToggle = $('.toggle-nav');
    this.menu = $('.menu ul');

    this.activeClass = 'active';

    // console.log('doing stuff here;');

    this.init();
    this.moreAdventures(controller);
    this.textExtract();
    this.contactModal();
  }

  init () {
    this.navToggle.click((e) => {
      e.preventDefault();

      let elem = $(e.currentTarget);
      elem.toggleClass(this.activeClass);
      this.menu.toggleClass(this.activeClass);
    });
  }

  open () {
    this.navToggle.addClass(this.activeClass);
    this.menu.addClass(this.activeClass);
  }

  close () {
    this.navToggle.removeClass(this.activeClass);
    this.menu.removeClass(this.activeClass);
  }

  contactModal () {
    $('.subscribe').click((e) => {
      e.preventDefault();

      // let elem = $(e.currentTarget);
      $('.contactModal').addClass('open');
    });

    $(document).click((e) => {
      if (e.target.matches('.contactModal, .close')) {
        e.preventDefault();
        $('.contactModal').removeClass('open');
      }
    });
  }

  textExtract () {
    $('.readSample').click((e) => {
      e.preventDefault();

      // let elem = $(e.currentTarget);
      $('.bookModal').addClass('open');
    });

    $(document).click((e) => {
      if (e.target.matches('.bookModal, .close')) {
        e.preventDefault();
        $('.bookModal').removeClass('open');
      }
    });
  }

  moreAdventures (controller) {
    // new ScrollMagic.Scene({
    //   triggerElement: '.main-content',
    //   triggerHook: 0.25
    // })
    //   .setClassToggle('#adv', 'is-active')
    //   .addTo(controller);

    // new ScrollMagic.Scene({
    //   triggerElement: '.footer-container',
    //   triggerHook: 'onEnter'
    // })
    //   // .setClassToggle('#adv', 'active')
    //   .setClassToggle('.share-me', 'is-active')
    //   .addTo(controller);

    $('.twitter-share').click(function (event) {
      let width = 575;
      let height = 400;
      let left = ($(window).width() - width) / 2;
      let top = ($(window).height() - height) / 2;
      let url = 'https://twitter.com/intent/tweet?url=';
      let opts = 'status=1,width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;

      let text = encodeURIComponent("Get the world's greatest adventure stories, on tap ->");
      let shareUrl = url + window.location.href + '&text=' + text;

      window.open(shareUrl, 'twitter', opts);

      return false;
    });

    $('.facebook-share').click(function (e) {
      e.preventDefault();
      window.open($('.facebook-share').attr('href'), 'fbShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
      return false;
    });

    $('.more-adventures').find('h6').click((e) => {
      e.preventDefault();

      let elem = $(e.currentTarget);
      elem.parent().toggleClass('active');
    });
  }
}

export default MainNav;
