class Intro {
  constructor (controller) {
    if (window.scrollY === 0) {
    this.introReverseTl = new TimelineMax({paused: true});

    this.introReverseTl
      .from($('#intro header'), 1.5, {
        autoAlpha: 0,
        ease: Power1.easeNone,
        scale: 2
      }, '0.2')
      .from($('#intro .layer-0'), 0.7, {
        scale: 1.2,
        y: '20%',
        ease: Power1.easeNone
      }, '0')
      .from($('#intro .scroll-hint'), 0.1, {
        autoAlpha: 0,
        y: '20%',
        ease: Power1.easeNone
      }, '0')
      .from($('#intro .layer-0-a'), 0.7, {
        scale: 1.2,
        y: '20%',
        autoAlpha: 0,
        ease: Power1.easeOut
      }, '0')
      .from($('#intro .layer-bg'), 0.7, {
        scale: 1.05,
        ease: Power1.easeNone
      }, '0')
      .from($('#intro .layer-1'), 2, {
        scale: 1.1,
        ease: Power1.easeInOut
      }, '0.2')
      .from($('#intro .layer-2'), 2, {
        scale: 1.2,
        ease: Power1.easeInOut
      }, '0.2')
      .from($('#intro .layer-3'), 2, {
        scale: 1.3,
        ease: Power1.easeInOut
      }, '0.2')
      .from($('#intro .layer-4'), 2, {
        scale: 1.4,
        ease: Power1.easeInOut
      }, '0.2')
      .from($('#intro .layer-5'), 2, {
        scale: 1.5,
        ease: Power1.easeInOut
      }, '0.2');

    // SCENE 5 - parallax effects on the intro slide backgrounds
    // move bcg container when intro gets out of the the view
  }

    this.introTl = new TimelineMax();

    this.introTl
      // Removed for performance
      // .to($('#intro .layer-1'), 1.4  {scale: 1.2, ease: Power1.easeNone}, '-=1.4');
      // .to($('#intro .layer-1'), 1.4  {scale: 1.2,
      //          ease: Power1.easeNone});
      .to($('#intro header'), 1.5, {
        autoAlpha: 0,
        ease: Power1.easeNone,
        scale: 2,
        y: '50%'
      }, '-=1')
      .to($('#intro .layer-bg'), 0.5, {
        autoAlpha: 0.8,
        ease: Power1.easeNone
      }, '0.5')
      // .to($('#intro .bcg'), 1.4, {
      //     y: '20%',
      //     ease: Power1.easeOut
      // }, '-=0.2')

      .to($('#intro .layer-0'), 1.5, {
        scale: 1.2,
        y: '35%',
        ease: Power1.easeNone
      }, '0')
      .to($('#intro .scroll-hint'), 0.1, {
        autoAlpha: 0,
        y: '20%',
        ease: Power1.easeNone
      }, '0')

      .to($('#intro .layer-0-a'), 1.5, {
        scale: 1.2,
        y: '35%',
        autoAlpha: 0,
        ease: Power1.easeOut
      }, '0')
      .to($('#intro .layer-bg'), 0.7, {
        scale: 1.05,
        ease: Power1.easeNone
      }, '0')
      .to($('#intro .layer-1'), 1, {
        scale: 1.1,
        ease: Power1.easeOut
      }, '0')
      .to($('#intro .layer-2'), 1, {
        scale: 1.2,
        ease: Power1.easeOut
      }, '0')
      .to($('#intro .layer-3'), 1, {
        scale: 1.3,
        ease: Power1.easeOut
      }, '0')
      .to($('#intro .layer-4'), 1, {
        scale: 1.4,
        ease: Power1.easeOut
      }, '0')
      .to($('#intro .layer-5'), 1, {
        scale: 1.6,
        y: '-20%',
        ease: Power1.easeOut
      }, '0');

    new ScrollMagic.Scene({
      triggerElement: '#intro',
      triggerHook: 0,
      duration: '100%'
    })
      // .setPin('#intro')

      .setTween(this.introTl)
      .addTo(controller);
  }
}

export default Intro;
