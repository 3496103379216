class Slides {
  constructor (controller) {
    // get all slides
    let slides = ['#slide02', '#slide03', '#slide04', '#books', '.animateInBg', '#book-detail', '#story-intro'];

    // SCENE 4 - parallax effect on each of the slides with bcg
    // move bcg container when slide gets into the view
    slides.forEach(function (slide, index) {
      $(slide).each(function (index) {
        let $bcg = $(this).find('.bcg');

        new ScrollMagic.Scene({
          triggerElement: this,
          triggerHook: 1,
          duration: '100%'
        })

          .setTween(TweenMax.from($bcg, 1, {
            y: '-25%',
            autoAlpha: 0.3,
            ease: Power0.easeNone
          }))

          .addTo(controller);
      });
    });
  }
}

export default Slides;
