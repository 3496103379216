class Videos {
  constructor (controller) {
    // get all the videos to play
    let videos = ['.video-background'];

    // Trigger for the video background to start playing
    videos.forEach(function (video, index) {
      new ScrollMagic.Scene({
        triggerElement: video,
        duration: '50%',
        triggerHook: 0 })
        .on('start', function () {
          let vid = $(video).get(0);
          if (vid) {
            vid.paused ? vid.play() : vid.pause();
          }
        })
        .on('end', function () {
          let vid = $(video).get(0);
          if (vid) {
            vid.paused ? vid.play() : vid.pause();
          }
        })
        // .addIndicators()
        .addTo(controller);
    });
  }
}

export default Videos;
