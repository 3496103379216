import Utilities from './utilities';

class Parallax {
  constructor () {
    let $parallaxContainer = $('body');
    let $parallaxItems = $parallaxContainer.find('.extra-parallax');

    let fixer = 0.0008; // experiment with the value
    let distance = 60;

    let mouseParallax = false;
    let gyroParallax = false;

    let lastScrollAt = Date.now();

    let timeout;

    function scrollStartStop () {
      let $this = $(this);

      if (Date.now() - lastScrollAt > 100) {
        $this.trigger('scrollstart');
      }

      lastScrollAt = Date.now();

      clearTimeout(timeout);

      timeout = setTimeout(function () {
        if (Date.now() - lastScrollAt > 99) {
          $this.trigger('scrollend');
        }
      }, 100);
    }

    if (mouseParallax) {
      $(document).on('scroll', scrollStartStop);

      $(document).on('scrollstart', function () {
        $(document).unbind('mousemove');
        if (gyroParallax) {
          $(window).unbind('devicemotion');
        }
      });

      $(document).on('scrollend', function () {
        if ($('#intro .title').isInViewport()) {

        }
        if (Modernizr.touch && window.DeviceMotionEvent) {
          if (gyroParallax) {
            window.addEventListener('devicemotion', setGyroParallax, true);
          }
        } else {
          $(document).on('mousemove', setMouseParallax);
        }
      });

      if (Modernizr.touch && window.DeviceMotionEvent) {
        if (gyroParallax) {
          window.addEventListener('devicemotion', setGyroParallax, true);
        }
      } else {
        $(document).on('mousemove', setMouseParallax);
      }
    }

    function setGyroParallax (event) {
      // ISSUE WITH THIS ON ANDROID. LOOK INTO GRYONORM
      // https://github.com/dorukeker/gyronorm.js

      let x = event.accelerationIncludingGravity.x;
      let y = event.accelerationIncludingGravity.y;
      // let z = event.accelerationIncludingGravity.z;

      x = Utilities.oneDecimal(x);
      y = Utilities.oneDecimal(y);
      // z = Utilities.oneDecimal(z);

      $parallaxItems.each(function (e) {
        let item = $(this);
        let speedX = item.data('speed-x');
        let speedY = item.data('speed-y');

        let newX = item.position().left - (distance * ((x + 10) / speedX) * 5);
        let newY = item.position().top - (distance * ((y + 10) / speedY) * 5);

        //   item.position().left += (newX - item.position().left) * .3;
        // item.position().top += (newY - item.position().top) * .3;

        item.css({
          'right': newX * 0.3,
          'top': newY * -0.2
        });
      });
    }

    function setMouseParallax (event) {
      let pageX = event.pageX - ($parallaxContainer.width() * 0.5); // get the mouseX - negative on left, positive on right
      let pageY = event.pageY - ($parallaxContainer.height() * 0.5); // same here, get the y. use console.log(pageY) to see the values

      // here we move each item
      $parallaxItems.each(function (e) {
        let item = $(this);
        let speedX = item.data('speed-x');
        let speedY = item.data('speed-y');

        //       TweenLite.to(item, 0.5, {
        //  x: (item.position().left + pageX * speedX )*fixer,    //calculate the new X based on mouse position * speed
        //  y: (item.position().top + pageY * speedY)*fixer
        // });

        // or use set - not so smooth, but better performance
        TweenLite.set(item, {
          x: (item.position().left + pageX * speedX) * fixer,
          y: (item.position().top + pageY * speedY / 3) * fixer
        });
      });
    }
  }
}

export default Parallax;
