class BuyNowButton {
  constructor () {
    this.buyNowButton = $('#buy-now-button');
    this.activeClass = 'active';
    this.listenNowButton = $('#listen-now-button');
    // :not(.listen-now)');
    this.init();
  }

  init () {
    this.buyNowButton.click((e) => {
      e.preventDefault();
      let elem = $('.buy-now');
      elem.toggleClass(this.activeClass);
    });

    $(document).click((e) => {
      if (!e.target.matches('.buy-now img, #buy-now-button') && $('.buy-now').hasClass(this.activeClass)) {
        e.preventDefault();
        $('.buy-now').removeClass(this.activeClass);
      }
    });

    this.listenNowButton.click((e) => {
      e.preventDefault();
      let elem = $('.listen-now');
      elem.toggleClass(this.activeClass);
    }).children().click((e) => {
      e.stopPropagation();
    });
  }
}

export default BuyNowButton;


