import Utilities from './utilities';
class BookFilter {
  constructor (controller) {


    $('#books-content .filters-tree select').selectric({
  arrowButtonMarkup: '<b class="btn">&#x25be;</b>'
});


    let filters = {};
    let $grid = $('.isotope');
    if ( $( "#books-content .filters" ).length ) {

      // set the dropdown if a querystring is set.
      let urlParams = new URLSearchParams(window.location.search);
      let seriesQs = urlParams.getAll('series');
      if (seriesQs.length > 0) {
        $("#selectbox1 option:contains(" + seriesQs + ")").attr('selected', 'selected');
      }

    this.orderSelect = $('#selectbox2');

    $('#books-content .filters select').selectric({
      arrowButtonMarkup: '<b class="btn">&#x25be;</b>'
    });

    // configure the selects
    $('#books-content .filters select .selectric-items').find('.selected').each(function( index ) {
      let $buttonGroup = $(this).parents('.filters-button-group');
      if ($buttonGroup.length) {
        $('.series-text').removeClass('is-active');
        let filterGroup = $buttonGroup.attr('data-filter-group');
        filters[ filterGroup ] = $buttonGroup.find(':selected').data('filter');
      }
      });

    // if (filters[ 'series' ].length > 0) {
    //   var showSeries = '.text-' + filters[ 'series' ].split('.').join("");
    // $(showSeries).addClass('is-active');
  // }
    let sortValue = this.orderSelect.find(':selected').data('sort-value');
    let sortAscending = (String(this.orderSelect.find(':selected').data('sort-ascending')) == "true");
    let filterValue = Utilities.concatValues(filters);

    $grid.imagesLoaded(function () {
      $grid.isotope({
        // options
        filter: filterValue,
        sortBy: sortValue,
        sortAscending: sortAscending,
        percentPosition: true,
        itemSelector: '.grid-item',
        masonry: {
          columnWidth: '.grid-sizer',
          gutter: '.gutter-sizer'
        },
        sortBy: 'releaseDate',
        sortAscending: false,
        // sorting data
        getSortData: {
          name: '.name',
          releaseDate: '.release-date parseInt',
          bookDate: '.book-date parseInt'
        }
      });
    });
  }
    $('.filters select').change((e) => {
        //hide the text

      // Get rid of any selected book
      $('.grid-information').removeClass('selected-book');
      // set the context of this to the event's current target
      let $this = $(e.currentTarget);
      // get group key
      let $buttonGroup = $this.parents('.filters-button-group');
      // if it's a filter button group
      if ($buttonGroup.length) {

        $('.series-text').removeClass('is-active');
        let filterGroup = $buttonGroup.attr('data-filter-group');
        // set filter for group
        filters[ filterGroup ] = $this.find(':selected').data('filter');
        var showSeries = '.text-' + filters[ 'series' ].split('.').join("");
        $(showSeries).addClass('is-active');
        // combine filters
      }
      // join together the filters
      let filterValue = Utilities.concatValues(filters);
      // set the sorting value
      let sortValue = this.orderSelect.find(':selected').data('sort-value');
      let sortAscending = (String(this.orderSelect.find(':selected').data('sort-ascending')) == "true");

      console.log(filters)
      // check to see if we want to show the download poster
      if (filters['series'] !== '.courtney-series') {
        $('.courtney-download-button').hide();
      } else {
        $('.courtney-download-button').show();
      }
      // do the filtering
      $grid.isotope({
        filter: filterValue,
        sortBy: sortValue,
        sortAscending: sortAscending });
      // scroll into view like a boss
      // controller.scrollTo($('.series-text').offset().top - 100);
      // console.log(.offset().bottom-75);
    });

    $('.grid-item img').click((e) => {
      $('.grid-information').removeClass('selected-book');

      let $this = $(e.currentTarget);
      let $gridInformation = $this.siblings('.grid-information');
      $gridInformation.show();
      $gridInformation.addClass('selected-book');

      Utilities.initIsInViewport();

      // scroll to the information box,
      // if we cant see the link
      if (!$this.parent().find('.grid-information a').isInViewport()) {
        controller.scrollTo($this.parent().find('.grid-information').offset().top -100);
      }
      // Should we be unbinding this??
      $(document).click((e) => {
        if (!e.target.matches('.cover')) {
          $('.grid-information').removeClass('selected-book');
        }
      });
    });
  }
}

export default BookFilter;
